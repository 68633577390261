<template>
  <!--
    * 策略类型编码  discountMethod (1立减 2红包 3 送券 4 赠送 5返比例)
    * 满足度量  condMeas
    * 优惠度量  discountMeas
    * 省比例  proportion
    * 让利类型  preferentialType  1.票折，2.红包，3.特价, 4.赠品
    * 活动类型  activityType (1->货找人，2->AB类，3->普通活动，4->特价，101->品种红包)
    -->

  <!--bigPreferentialType  1.票折  2.特价  3.赠品  4.票折+红包  5.票折+特价  6.票折+赠品  7.特价+赠品-->
  <!--activityStrategyReduceVOS 数组中的 preferentialType  让利类型  (1.票折，2.红包，3.特价, 4.赠品)-->

  <div v-if="product.activityVo">
    <template v-if="product.activityVo.activityStrategyReduceVOS && product.activityVo.activityStrategyReduceVOS.length">
      <div v-for="(item, index) in product.activityVo.activityStrategyReduceVOS" :key="index">
        <!--非票折+特价，且不是特价品种；或者为票折+特价，且只有一个活动-->
        <div
          v-if="(product.activityVo.bigPreferentialType != 5 && item.preferentialType != 3) || (product.activityVo.bigPreferentialType == 5 && product.activityVo.activityStrategyReduceVOS.length == 1)"
          class="act"
          :style="{'background': setActiveTagBgColor(item.preferentialType)}">
          <span
            class="act-type"
            :style="{'background': setActiveNameBg(item.preferentialType)}">
            {{ item.preferentialType | setActivityTagActiveName }}
          </span>

          <span class="act-name" v-if="item.preferentialType != 4">
            <span>每购{{ item.condMeas }}{{ item.sellUnit }}</span>
            <i> {{ item.preferentialType | setTypeName }} </i>
            <span>{{ item.discountMeas }}元</span>
            <span v-if="item.preferentialType == 1">=省{{ parseInt(item.proportion * 100) }}%</span>
            <span v-else-if="item.preferentialType == 2" style="margin-left: 8rpx;">通用红包</span>
          </span>

          <span class="act-name" v-else>
            <!--赠品为非药品：每购12盒送2袋洗衣粉-->
            <!--赠品为本品：每购12盒送2盒（仅+0.02元）-->
            <!--赠品为非本品：每购12盒送2盒阿莫西林胶带（仅+0.02元）-->
            <span>每购{{ item.condMeas }}{{ item.sellUnit }}</span>
            <i> 送 </i>
            <span>{{ item.discountMeas }}{{ item.giftInfos[0].sellUnit }}</span>

            <template v-if="item.giftInfos[0]">
              <!--giftTypeCode  1.药品  2.非药品-->
              <template v-if="item.giftInfos[0].giftTypeCode == 1">
                <!--赠品信息不是本商品-->
                <span v-if="product.activityVo.productCode != item.giftInfos[0].productCode">{{ item.giftInfos[0].productName }}</span>
                <span>（仅+0.01/{{ item.giftInfos[0].sellUnit }}）</span>
              </template>
              <span v-else>{{ item.giftInfos[0].productName }}</span>
            </template>
          </span>
        </div>

        <!--特价品种显示-->
        <div class="special-offer-activity" style="white-space: break-spaces;" v-else-if="item.preferentialType == 3">
          <div v-for="(item, i) in activeInfoList" :key="i" style="font-size: 12px;">
            <template v-for="(active, i) in item">
              <span v-if="i % 2 == 0">{{ active }},</span>
              <span v-else class="rd">{{ active }}</span>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import productActivity from '@/components-v2/product/productActivity'

export default {
  name: "ProductActivityTags",
  components: {},
  data() {
    return {
      activeInfoList: []
    }
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  computed: {},
  watch: {
    product: {
      deep: true,
      immediate: true,
      handler(val) {
        // 商品新接口逻辑
        if (val.activityVo && val.activityVo.activityStrategyReduceVOS) {
          val.activityVo.activityStrategyReduceVOS.forEach(item => {
            if (item.preferentialType == 3) {
              let arr;
              if (val.activityVo.bigPreferentialType == 5) {
                arr = [val.activityVo.reward, val.activityVo.reward1];
              } else {
                arr = [val.activityVo.reward];
              }
              this.activeInfoList = arr.map(key => {
                return key.split(",")
              })
            }
          })
        }
      }
    }
  },
  mixins: [productActivity],
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang="less">
.act {
  //text-overflow: ellipsis;
  //white-space: nowrap;
  //overflow: hidden;
  margin-top: 5px;
  border-radius: 9px;

  .act-type {
    width: 40px;
    height: 20px;
    line-height: 20px;
    display: inline-block;
    color: #ffffff;
    font-size: 0.65rem;
    border-radius: 9px 0px 9px 9px;
    text-align: center;
  }

  .act-name {
    font-size: 12px;
    margin-left: 5px;

    i {
      color: #e5432e;
      font-style: normal;
    }
  }
}

// 商品列表特价活动标签
.special-offer-activity {
  border-radius: 11px;
  background: #FFF3F3;
  color: rgba(0, 0, 0, 0.9);
  font-size: 10px;
  font-weight: initial;
  padding: 4px 10px !important;
  line-height: 17px;
  margin-top: 6px !important;

  .rd {
    color: #E5432E;
    font-weight: 500;
  }
}

.act-item {
  .act {
    margin-left: 0;
  }
}
</style>
